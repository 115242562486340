input {
  width: 100%;
  height: 100px;  
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}

.simple-keyboard {
  max-width: 100%;
}

.simple-keyboard.hg-theme-default.myTheme {
  
  
  
  
}

.simple-keyboard.hg-layout-default .hg-button {
    height: 50px;;
}

.simple-keyboard.hg-layout-default .hg-button.hg-red {
  background: rgb(255, 0, 0, 0.7);
  color: white;
}
.simple-keyboard.hg-layout-default .hg-button.hg-green {
  background: rgb(0, 255, 0, 0.7);
  color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg-highlight {
  box-shadow: 0px 0px 40px 5px rgba(255, 255, 0, 0.5);
  z-index: 1;
}